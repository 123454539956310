<template>
  <div>
    <div class="wrapper">
      <!-- section dashboard -->
      <section
        class="section section-blue jpadding section-unfixed"
      >
        <div class="jcard jcard-main jcard-nohover">
          <h4 class="mb-4 mt-3">All Subscriptions</h4>

          <div class="table-responsive">
            <table class="table table-light">
              <thead>
                <tr>
                  <th>#</th>
                  <th scope="col">Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">Billed</th>
                  <th scope="col">Expiry</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(subscription, index) in subscriptionsList"
                  :key="subscription.id"
                >
                  <td>{{ subscription.agreementId }}</td>
                  <td>{{ subTypeList[subscription.aacType] }}</td>
                  <td>Talkii {{ subscription.planName }}</td>
                  <td>{{ subscription.duration }}</td>
                  <td>{{ getFormatDate(subscription.next_billing_time) }}</td>
                  <td>
                    <span class="badge badge-success text-uppercase">{{
                      subscription.status
                    }}</span>
                  </td>
                  <td>
                    <button
                      class="jbtn jbtn-icon jbtn-blue"
                      @click="openSubscription(index)">
                      <i class="fa fa-search"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
// import C from "@/constants";
import PLANS from "@/plans";
import axios from "axios";

export default {
  name: "Dashboard",
  data() {
    return {
      subStatusList: PLANS.STATUS_INDEX,
      subTypeList: PLANS.TYPE_INDEX,
      firstname: "",
      lastname: "",
      homeTitle: "Bonjour ",

      //userId: firebase.auth().currentUser.uid,
      subscriptionsList: [PLANS.DEFAULT_SUB],
    };
  },
  methods: {
    // startContrat(type) {
    //   this.$store.commit("startAddContrat");
    // },
    allSubscriptions: function() {
      const id = firebase.auth().currentUser.uid;
      // if (this.userId != "") {
      axios
        .post("/api/functions.php", {
          request: 5,
          userId: id,
        })
        .then((response) => {
          console.log(response.data);
          this.subscriptionsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openSubscription(index) {
      this.$store.commit("setSubscription", this.subscriptionsList[index]);
      this.$router.push({
        name: "Subscription",
        params: { id: this.subscriptionsList[index].id },
      });
    },
    getFormatDate(date) {
      console.log(date);
    },
  },
  mounted() {
    this.allSubscriptions();
  },
};
</script>

<style scoped></style>
